import { error } from '@/services/Log'
import { PhysicalItem } from '@/types/Cart'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

type MinimumLineItem = Pick<PhysicalItem, 'sku' | 'quantity' | 'salePrice' | 'name' | 'imageUrl' | 'url'>
type MinimumUser = { email: string, firstName: string, lastName: string }
type MinimumOrder = { orderId:number }
type ListrakSubscriptionPointChannel = 'SMS' | 'email'
type ListrakSubscriptionPointInitializer = (
  subscriberId: string,
  inputIdentifier: string,
  submitButtonIdentifier: string,
) => (channel: ListrakSubscriptionPointChannel) => void
export const LISTRAK_EMAIL_IDENTIFIER = 'email'

export const getListrak = () => {
  if (!window._ltk) {
    throw new Error('No Listrak integration found')
  }
  return window._ltk
}
export const initializeSubscriptionPoint: ListrakSubscriptionPointInitializer = (
  subscriberId,
  inputIdentifier,
  submitButtonIdentifier,
) => (channel) => {
  try {
    const listrak = getListrak()
    listrak.Signup?.New(subscriberId, inputIdentifier,
      listrak.Signup?.TYPE?.CLICK, submitButtonIdentifier, channel)
  } catch (e) {
    error('Listrak::onSubscriptionPointInitialization failed.', e)
  }
}
export const initializeEmailSubscriptionPoint = (
  subscriberId: string,
  inputIdentifier: string,
  submitButtonIdentifier: string,
) => {
  initializeSubscriptionPoint(subscriberId, inputIdentifier, submitButtonIdentifier)('email')
}

export const trackFieldSubscriptionPoint = (
  subscriberId: string,
  fieldIdentifier: string,
  key?: string,
) => {
  try {
    const listrak = getListrak()
    listrak.Signup?.SetField(subscriberId, fieldIdentifier,
      key ? { key } : undefined)
  } catch (e) {
    error('Listrak::trackFieldSubscriptionPoint failed.', e)
  }
}

export const trackOptInSubscriptionPoint = (
  subscriberId: string,
  fieldIdentifier: string,
) => {
  try {
    const listrak = getListrak()
    listrak.Signup?.SetOptIn(subscriberId, fieldIdentifier)
  } catch (e) {
    error('Listrak::trackOptInSubscriptionPoint failed.', e)
  }
}

export const onEmailUpdate = (email: string) => {
  try {
    const listrak = getListrak()
    listrak.SCA?.Update(LISTRAK_EMAIL_IDENTIFIER, email)
  } catch (e) {
    error('Listrak::onSubscription failed.', e)
  }
}

export const captureEmail = (elementIdOrName: string) => {
  try {
    const listrak = getListrak()
    listrak.SCA?.CaptureEmail(elementIdOrName)
  } catch (e) {
    error('Listrak::captureEmail failed.', e)
  }
}

export const listrakSubmitCart = (physicalItems: readonly MinimumLineItem[], email?: string) => {
  try {
    const listrak = getListrak()
    if (!physicalItems && listrak?.SCA) {
      listrak.SCA.ClearCart()
    } else {
      if (email) {
        listrak.SCA?.Update(LISTRAK_EMAIL_IDENTIFIER, email)
      }
      physicalItems.forEach(
        (item) => listrak.SCA?.AddItemWithLinks(
          item.sku,
          item.quantity,
          item.salePrice,
          item.name,
          item.imageUrl,
          item.url,
        ),
      )
      listrak.SCA?.Submit()
    }
  } catch (e) {
    error('Failed to send log to listrak', e)
  }
}

export const listrakProductBrowse = (sku: string) => {
  try {
    const listrak = getListrak()
    listrak?.Activity?.AddProductBrowse(sku)
  } catch (e) {
    console.error(e)
  }
}

export const listrakOrderTracking = async (
  {
    user, order, lineItems, itemsSubtotal, shippingCost, taxTotal, grandTotal,
  }: {
    user: MinimumUser,
    order: MinimumOrder,
    lineItems: MinimumLineItem[],
    itemsSubtotal: number,
    shippingCost: number,
    taxTotal: number,
    grandTotal: number
  },
) => {
  try {
    const [
      {
        default: isEmpty,
      },
    ] = await allPromisesWithRetries(() => [
      import('lodash/isEmpty'),
    ])

    const listrak = getListrak()
    if (listrak && !isEmpty(order && user)) {
      listrak.Order.SetCustomer(user.email, user.firstName, user.lastName)
      listrak.Order.OrderNumber = order.orderId
      listrak.Order.ItemTotal = itemsSubtotal
      listrak.Order.ShippingTotal = shippingCost
      listrak.Order.TaxTotal = taxTotal
      listrak.Order.OrderTotal = grandTotal

      lineItems.forEach(({
        sku,
        quantity,
        salePrice,
      }) => {
        listrak.Order.AddItem(sku, quantity, salePrice)
      })
      listrak.Order.Submit()
    }
  } catch (e) {
    console.error(e)
  }
}

export default {
  getListrak,
  onEmailUpdate,
  captureEmail,
  listrakSubmitCart,
  listrakProductBrowse,
  listrakOrderTracking,
}
