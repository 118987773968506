/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { STORE_PHONE } from '@/data/constants'
import Subscription from '@/services/Subscription'
import { error } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { initializeEmailSubscriptionPoint } from '@/services/Tracking/Listrak'
import { useIsCitizensPayEnabled } from '@/hooks/useIsCitizensPayEnabled'
import FacebookIcon from './FacebookIcon'
import styles from './Footer.module.scss'

const FOOTER_SUBSCRIBER_ID = 'ZGalFooter'
const EMAIL_INPUT_IDENTIFIED = 'nl_email'
const SUBMIT_BUTTON_IDENTIFIED = 'email-subscribe-button'
const Footer = () => {
  const [subscribeModal, setSubscribeModal] = useState<boolean>(false)
  const [subscribeMessage, setSubscribeMessage] = useState<string | null>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const isCitizensPayEnabled = useIsCitizensPayEnabled()

  useEffect(() => {
    // Listrak subscription point
    try {
      initializeEmailSubscriptionPoint(
        FOOTER_SUBSCRIBER_ID,
        EMAIL_INPUT_IDENTIFIED,
        SUBMIT_BUTTON_IDENTIFIED,
      )
    } catch (e) {
      error('Listrak subscription point initialization failed.', e)
    }
  })
  const handleSubmit = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    const email = emailRef.current?.value
    const { data: { message, success } } = await Subscription.subscribe({ email: email || '' })

    // Marketing channels
    if (success && email) {
      try {
        const [
          { onNewsLetterSubscribe },
        ] = await allPromisesWithRetries(() => [
          import('@/services/Tracking/Analytics/onNewsLetterSubscribe'),
        ])
        onNewsLetterSubscribe(email)
      } catch (e) {
        error('handleSubmitNewsletterSubscribe failed to send analytic.', e)
      }
    }

    setSubscribeMessage(String(message))
    setSubscribeModal(true)
  }

  const closeModal = () => {
    setSubscribeModal(false)
  }

  return (
    <footer className="footer" role="contentinfo">
      <div className="container">
        <article className="footer-info-col footer-info-col--social" data-section-type="footer-webPages">
          <div className="borderTop" />
          <ul className="socialLinks socialLinks--alt">
            <li className="socialLinks-item">
              <a
                className="icon icon--facebook"
                href="https://www.facebook.com/ZGallerie"
                target="_blank"
                aria-label="facebook"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
            </li>
            <li className="socialLinks-item">
              <a
                className="icon icon--twitter"
                href="http://twitter.com/zgallerie"
                target="_blank"
                aria-label="twitter"
                rel="noreferrer"
              >
                <svg>
                  <use href="#icon-twitter" />
                </svg>
              </a>
            </li>
            <li className="socialLinks-item">
              <a
                className="icon icon--stumbleupon"
                href="http://www.houzz.com/pro/zgallerie/"
                target="_blank"
                aria-label="stumbleupon"
                rel="noreferrer"
              >
                <svg>
                  <use href="#icon-stumbleupon" />
                </svg>
              </a>
            </li>
            <li className="socialLinks-item">
              <a
                className="icon icon--pinterest"
                href="https://pinterest.com/zgallerie/"
                target="_blank"
                aria-label="pinterest"
                rel="noreferrer"
              >
                <svg>
                  <use href="#icon-pinterest" />
                </svg>
              </a>
            </li>
            <li className="socialLinks-item">
              <a
                className="icon icon--instagram"
                href="http://instagram.com/zgallerie#"
                target="_blank"
                aria-label="instagram"
                rel="noreferrer"
              >
                <svg>
                  <use href="#icon-instagram" />
                </svg>
              </a>
            </li>
          </ul>
          <div className="borderTop" />
        </article>
        <div className="subContainer">
          <section className="footer-info">
            <section>
              <article className="footer-info-col footer-info-col--small" data-section-type="footer-webPages">
                <input type="checkbox" id="zgallerie" name="footer-nav" />
                <label htmlFor="zgallerie" className="footer-info-heading">Z Gallerie</label>
                <ul className={clsx('footer-info-list', styles.footerUl)}>
                  <li><a href="/t-about">About Us</a></li>
                  <li><a href="/t-designtradeprogram">Design Trade Program</a></li>
                  <li><a href="/t-careers">Careers</a></li>
                  <li><a href="/t-info-vendor-information">Vendor Information</a></li>
                  <li><a href="/t-accessibility-statement">Accessibility Statement</a></li>
                </ul>
              </article>

              <article className="footer-info-col footer-info-col--small">
                <input type="checkbox" id="shopping-info" name="footer-nav" />
                <label htmlFor="shopping-info" className="footer-info-heading">Shopping Info</label>
                <ul className={clsx('footer-info-list', styles.footerUl)}>
                  <li><a href="/t-store-locations">Store Locations</a></li>
                  <li><a href="/customer-service/giftservices">Gift Services</a></li>
                  {isCitizensPayEnabled && <li><a href="/t-citizens-pay">Citizens Pay®</a></li>}
                  <li><a href="/t-military-discount">Military Discount Program</a></li>
                  <li><a href="/sitemap">Sitemap</a></li>
                </ul>
              </article>

              <article className="footer-info-col footer-info-col--small">
                <input type="checkbox" id="customer-service" name="footer-nav" />
                <label htmlFor="customer-service" className="footer-info-heading">Customer Service</label>
                <ul className={clsx('footer-info-list', styles.footerUl)}>
                  <li><a href="/t-track-order">Track Your Order</a></li>
                  <li><a href="/customer-service/shipping-handling">Shipping / Delivery</a></li>
                  <li><a href="/customer-service/returns-exchanges">Returns / Exchanges</a></li>
                  <li>
                    <a href="/__statics/documents/measuringfordelivery.pdf" target="_blank">Measuring Furniture</a>
                  </li>
                  <li><a href="/customer-service/safety-recalls">Safety Recalls</a></li>
                  <li><a href="/customer-service/faq">General FAQs</a></li>
                </ul>
              </article>

              <article className="footer-info-col footer-info-col--small" data-section-type="storeInfo">
                <input type="checkbox" id="info" name="footer-nav" />
                <label htmlFor="info" className="footer-info-heading">Connect With Us</label>
                <ul className={clsx('footer-info-list', styles.footerUl)}>
                  <li>
                    <a href={`tel:${STORE_PHONE}`}>{STORE_PHONE}</a>
                  </li>
                  <li><a href="/customer-service/customer-service">Contact Us</a></li>
                </ul>
              </article>
            </section>
            <article className="footer-info-col" data-section-type="newsletterSubscription">
              <h5 className={clsx('footer-info-heading', styles.emailHeading)}>Sign Up for Email Updates</h5>
              <p className={styles.mobilePromotion}>
                From us to you | 20% off your first order. Valid on reg-price items only.
              </p>
              <ul className={styles.emailList}>
                <li>From us to you | 20% off your first order. Valid on reg-price items only.</li>
                <li>
                  Be the first to hear about new products, design tips, special events and sales.
                </li>
              </ul>
              <form
                className="form"
                id="newsletter-subscribe"
                action=""
                method=""
                onSubmit={(e) => {
                  handleSubmit(e)
                    .catch((err) => {
                      error('Failed to handleSubmit', err)
                    })
                }}
              >
                <fieldset className="form-fieldset">
                  <input type="hidden" name="action" value="subscribe" readOnly />
                  <input type="hidden" name="nl_first_name" value="bc" readOnly />
                  <input type="hidden" name="check" value="1" readOnly />
                  <div className="form-field">
                    <label className="form-label is-srOnly" htmlFor="nl_email">Email Address</label>
                    <div className={styles.newsletterSubscription}>
                      <input
                        className={`form-input ${styles.newsletterSubscriptionInput}`}
                        id={EMAIL_INPUT_IDENTIFIED}
                        name={EMAIL_INPUT_IDENTIFIED}
                        type="email"
                        required
                        ref={emailRef}
                        defaultValue=""
                        placeholder="Enter Email Address"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        title="example@example.com"
                      />
                      <input
                        id="subscribe-button-desktop"
                        name={SUBMIT_BUTTON_IDENTIFIED}
                        className={`form-prefixPostfix-button--postfix-desktop ${styles.newsletterSubscriptionDesktop}`}
                        type="submit"
                        value=""
                        aria-label="sign up"
                      />
                      <input
                        id="subscribe-button-mobile"
                        name={SUBMIT_BUTTON_IDENTIFIED}
                        className={`button button--primary form-prefixPostfix-button--postfix-mobile ${styles.newsletterSubscriptionMobile}`}
                        type="submit"
                        value="Sign Up"
                        readOnly
                      />
                    </div>
                  </div>
                </fieldset>
              </form>
              {subscribeModal && (
                <div id="subscribeModal" className="subscribeModal" style={{ display: 'block' }}>
                  <div className="modal-content">
                    <span className="close newsletterClose" onClick={closeModal} aria-hidden="true">
                      <img src="/content/images/close-gray.svg" alt="close modal" />
                    </span>
                    <h3 id="subscriptionSuccess">{subscribeMessage}</h3>
                    <p id="successSubLine" />
                    <div className="catalogDiv ssr-justify-center">
                      <a href="https://catalog.zgallerie.com">
                        <img src="/__statics/images/CatalogCover.jpg" alt="catalog" />
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </article>
          </section>
          <div className={styles.footerLinks}>
            <a href="/customer-service/privacy-policy">Privacy Policy</a>
            {' |'}
            <a href="/customer-service/terms-and-conditions">Terms & Conditions</a>
            {' |'}
            <a href="/customer-service/privacy-policy">Ad Choices</a>
            {' |'}
            <a href="https://www.mcafeesecure.com/RatingVerify?ref=www.zgallerie.com">Security Mc Afee Secure</a>
            {' |'}
            <a href="/customer-service/ca-transparency-acta">California Supply Chains Act</a>
            {' |'}
            <a href="/customer-service/ccpa-addendum#personal-information-sales-opt-out-and-opt-in-rights">Do not Sell My Personal Information</a>
          </div>
          <div className={styles.footerCopyright}>
            <p className={styles.poweredBy}>
              &copy;
              {(new Date().getFullYear())}
              {' '}
              DirectBuy Home Improvement, Inc. DBA Z Gallerie, All Rights Reserved
              {' '}
            </p>
          </div>
          <div className={styles.mcAfeeContainer}>
            <img src="/__statics/images/McAfee.png" width="83" alt="macfee logo" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
