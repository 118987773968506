import Axios from 'axios'
import { SEARCH_ORIGIN } from '@/services/Configuration'

const Subscription = {
  subscribe: async (customer) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const data = (await Axios.post(`${SEARCH_ORIGIN}/sf/customer/subscribe/`, customer, { headers }))
    return data
  },
}

export default Subscription
