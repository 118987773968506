// import { useEffect, useState } from 'react'

// import { getExperiment } from '@/helpers/common'
// import {
//   CITIZENS_PAY_EXPERIMENT_ID,
//   ENABLE_CITIZENS,
// } from '@/services/Configuration'

export const useIsCitizensPayEnabled = () => false
// const [isCitizensPayEnabled, setIsCitizensPayEnabled] = useState(false)
// useEffect(() => {
//   if (ENABLE_CITIZENS) {
//     getExperiment({ experimentId: CITIZENS_PAY_EXPERIMENT_ID })
//       .then((variant) => {
//         if (variant === '1') {
//           setIsCitizensPayEnabled(true)
//         }
//       })
//   }
// }, [
//   ENABLE_CITIZENS,
//   CITIZENS_PAY_EXPERIMENT_ID,
//   getExperiment,
// ])

export default useIsCitizensPayEnabled
